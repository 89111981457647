import { NavBarHandler } from "terra-one";
//import mxpData from "./json/navbar_GATSBY.json";

import rawContentfulData from "./json/navbar.json";
import productsData from "./json/all-products.json";
import { createProductTrialPageLink } from "./utils/createProductTrialPageLink";
import React from "react";
// import UberflipIcon from "./UberflipIcon/UberflipIcon";

const currentLocale = process.env.REACT_APP_LOCALE || 'en';
const baseUrl = process.env.REACT_APP_BASE_URL;

const formatLink = (link, currentLocale = "en") => {
  // If a link beings with '/' it is an internal link and we need to add the locale onto the path
  if (link && link.startsWith("/") && !link.startsWith(`/${currentLocale}`)) {
    return `${baseUrl}/${currentLocale}${link}`
  } else {
    return link
  }
}

const formatFeaturedLinks = (featuredLinks) => {
  if (Array.isArray(featuredLinks) && featuredLinks.length > 0) {
    return featuredLinks?.filter(link => {
      return (
        link?.__typename === "ExternalLink" ||
        link?.__typename === "PageBase" ||
        link?.__typename === "NavigationItemLink"
      )
    })?.map(link =>
      link?.__typename === "NavigationItemLink" 
      ? formatNavOptions(link?.pageLink) :
      link?.__typename === "ExternalLink"
        ? {
            url: link?.url,
            text: link?.text,
            type: "link",
            external: true,
            contentfulId: link?.sys?.id,
          }
        : link?.__typename === "PageBase"
        ? {
            url: formatLink(`/${link?.slug}`, currentLocale),
            text: link?.pageName,
            type: "link",
            contentfulId: link?.sys?.id,
          }
        : {}
    )    
  }

  return [];
}

// const formatIcons = icon => {
//   let machineNameIcon

//   if (!icon || typeof icon !== "string") {
//     return
//   }

//   if (
//     icon.toLowerCase().startsWith("open") ||
//     icon.toLowerCase().startsWith("enclosed") ||
//     icon.toLowerCase().startsWith("info")
//   ) {
//     machineNameIcon = `ter-icon--${icon
//       .toLowerCase()
//       .replace(/ /g, "-")}`
//   } else {
//     machineNameIcon = `mxp-icon-${icon.toLowerCase().replace(/ /g, "-")}`
//   }
//   return <UberflipIcon type={machineNameIcon} size="16" />
//   // return machineNameIcon
// }

function formatNavOptions(d) {
  // NavItemLink
  if (d?.__typename === 'NavigationItemLink') {
    return {
      ...formatNavOptions(d?.pageLink),
      text: d?.pageLink?.pageName || d?.title,
      external: d?.pageLink?.typeName === "ExternalLink" ? true : false,
      contentfulId: d?.sys?.id,
      // uberflipIcon: formatIcons(d?.icon?.icon), TO-DO: resolve cross-domain issues with icon loading
      description: d?.description,
      typeName: d?.pageLink?.__typename,
    }
  }
  // PAGEBASE
  else if (d?.__typename === 'PageBase') {
    return {
        url: `${baseUrl}/${currentLocale}/${d?.slug}`,
        text: d?.pageName,
        type: "link",
        external: false,
        contentfulId: d?.sys?.id,
    }
  // PRODUCT PAGE
  } else if (d?.__typename === 'PageProductPage') {
    return {
      url: `${baseUrl}/${currentLocale}/products/${d?.slug}`,
      text: d?.pageName,
      type: "link",
      external: false,
      contentfulId: d?.sys?.id,
  }    
  // LINK
  } else if (d?.__typename === 'ExternalLink') {
      return {
        url: d?.url || d?.externalUrl,
        text: d.text,
        type: "link",
        external: true,
        contentfulId: d?.sys?.id,
      }
  } else if (d?.__typename === "Link") {
    return {
      url:
        d?.internalLink
          ? `${baseUrl}/${currentLocale}/${d?.internalLink}`
          : `${baseUrl}/${currentLocale}/${d?.link?.url}`,
      text: d?.text,
      type: "link",
      icon: null,
      internalLink: false,
      external: false,
      contentfulId: d?.sys?.id,
    }
  } else if (d?.__typename === "ProductTrialPage") {
    return createProductTrialPageLink(
      productsData?.data?.productInformationCollection?.items,
      d?.product?.sys?.urn,
      currentLocale,
      null
    )
  // COLLECTION
  } else if (d?.navigationItemsCollection) {
      const navItems = d?.navigationItemsCollection?.items?.map((d) => formatNavOptions(d));
      if (d?.title) {
          const featuredLinks = formatFeaturedLinks(d?.featuredLinksCollection?.items);
          const featuredCategory = {
            title: d?.featuredCategory?.title,
            contentfulId: d?.featuredCategory?.sys?.id,
            links: d?.featuredCategory?.navigationItemsCollection?.items?.map((d) => formatNavOptions(d)),
            subCategoryLink: d?.featuredCategory?.subCategoryLink?.pageLink?.url ? formatNavOptions(d?.featuredCategory?.subCategoryLink?.pageLink) : null, 
          }
          
          const featuredPromo = {
            title: d?.featuredPromo?.title,
            contentfulId: d?.featuredPromo?.sys?.id,
            image: d?.featuredPromo?.image && {
              focalPointImage: {
                title: d?.featuredPromo?.image?.title,
                url: d?.featuredPromo?.image?.image?.url,
              }
            },
            description: d?.featuredPromo?.description,
            pageLink: d?.featuredPromo?.pageLink && {
              url: formatLink(`/${d?.featuredPromo?.pageLink?.slug}`, currentLocale),
              text: d?.featuredPromo?.pageLink?.pageName,
            },
          }
          const navItemType = (rawContentfulData?.data?.mainNavigation?.megaNavVariant === true && d?.type === "category") ? "megaNav" : d?.type;
          return {
              text: d?.title,
              title: d?.title,
              type: navItemType,
              contentfulId: d?.sys?.id,
              featuredLinksTitle: d?.featuredLinksCollection?.length
                ? d.featuredLinksSectionTitle
                : null,
              featuredLinks: featuredLinks,
              links: navItems,
              featuredSection: d?.featuredCategory && featuredCategory,
              navCategoryPromo: d?.featuredPromo && featuredPromo,
              displayChevrons: d?.displayChevrons,
              disableLinkDescriptions: d?.disableLinkDescriptions,
              subCategoryLink: formatNavOptions(d?.subCategoryLink)
          };
      } else {
          return navItems;
      }
  } else {
    console.log('ERROR', d)
    return {};
  }
}

const NavBar = () => {
  const logo = {
    url:
        rawContentfulData?.data?.mainNavigation?.lightAltLogo?.image?.file?.url ||
        rawContentfulData?.data?.mainNavigation?.trimbleLogo?.brandfolderAsset?.[0]?.url ||
        rawContentfulData?.data?.mainNavigation?.trimbleLogo?.image?.url,
    altText: rawContentfulData?.data?.mainNavigation?.trimbleLogo?.altText || "Trimble Logo",
    link: {
      url: `${baseUrl}/${currentLocale}`,
    },
  };
  
  const featuredLink = rawContentfulData?.data?.mainNavigation?.featuredLink
  ? {
      url:
        rawContentfulData?.data?.mainNavigation?.featuredLink?.url ||
        formatLink(`/${rawContentfulData?.data?.mainNavigation?.featuredLink?.slug}`, currentLocale),
      text:
        rawContentfulData?.data?.mainNavigation?.featuredLinkText ||
        rawContentfulData?.data?.mainNavigation?.featuredLink?.text ||
        rawContentfulData?.data?.mainNavigation?.featuredLink?.pageName,
      type: "link",
    }
  : null;

  const navOptions = formatNavOptions(rawContentfulData.data.mainNavigation);

  const isExpanded = rawContentfulData?.data?.mainNavigation?.isExpanded;

  const displaySectorName = rawContentfulData?.data?.mainNavigation?.sectorName;
  const divisionName =
    isExpanded && displaySectorName
      ? rawContentfulData?.data?.mainNavigation?.sectorName// || applicationData?.divisionName
      : "";

  const isMegaNav = rawContentfulData?.data?.mainNavigation?.megaNavVariant === true ? true : false;
  const data = {
    logo: logo,
    featuredLink: featuredLink,
    navOptions: navOptions,
    divisionName: divisionName,
    rightLinks:null,
    navCTA:{
      link:{},
      className:"ter-button--primary--1"
    },
    isExpanded: isExpanded,
    disableLanguageRegion:true,
    disableSectorFlyOut:true,
    disableLogin:true,
    disableSearch:true,
    sectorFlyoutData:null,
    languageSelectorData:null,
    history:null,
  };

  const applicationData = {
    cancelLabel:"Cancel",
    divisionName:"Gatsby",
    languageEnabled:false,
    learnMoreText:"Learn More",
    regionEnabled:false,
    saveLabel:"Save",
    siteTitle:"Trimble Gatsby!",
    submitButtonText:"Submit",
    homeButtonText:"Home",
    searchResultsLabelText:"More results",
    searchSearchPlaceholderText:"Products, industries, support, news and more...",
    searchResetButtonText:"Reset",
    searchRecentSearchesText:"Your Recent Searches",
    searchCommonSearchesText:"Common Searches"
  };

  return (
      <NavBarHandler
        data={data}
        innerPageNavData={null}
        history={null}
        applicationData={applicationData}
        setSearchDeployed={() => {}}
        megaNavVariant={isMegaNav}
      />
  );
};

export default NavBar;
