import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import NavBar from './NavBar';
import Footer from './Footer';

const navbarContainer = document.getElementById('mxp-navbar');
const navbarRoot = createRoot(navbarContainer);
navbarRoot.render(<NavBar />);

const footerContainer = document.getElementById('mxp-footer');
const footerRoot = createRoot(footerContainer);
footerRoot.render(<Footer />);
