
export const createProductTrialPageLink = (
  products,
  link,
  locale,
  button,
  siteIdentifier,
  Link
) => {
  if (!products || !link) {
    return
  }
  const entryId = link?.split("/citn2sn5tdjr/entries/")?.[1] 
  ? link?.split("/citn2sn5tdjr/entries/")?.[1] 
  : link?.split("/citn2sn5tdjr/environments/master/entries/")?.[1] 
    ? link?.split("/citn2sn5tdjr/environments/master/entries/")?.[1] 
    : "";

  const findProductFromList = products?.find(product => {
    return (
      product?.productTrialId && product?.sys?.id === entryId
    )
  })

  if (findProductFromList && findProductFromList?.productName) {
    return {
      url: findProductFromList?.productName
        ? `${process.env.REACT_APP_BASE_URL}/${locale}/products/${findProductFromList?.productName
            .toLowerCase()
            .replaceAll(" ", "-")}/trial`
        : "",
      text:
        button?.text ||
        findProductFromList?.productName,
      contentfulId: entryId,
      type: "link",
      external: false,
      publicPage: true,
      Link: Link
    }
  }
}
